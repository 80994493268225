import React, { useEffect } from 'react';
import { Image, HeaderDeco } from '../components';
import { useSlideshow } from '../components/SlideshowProvider';
import title from '../assets/images/gift-box/title.svg';
import deco from '../assets/images/gift-box/deco.svg';
import giftboxOpen from '../assets/images/gift-box/SmartPro-GiftBox-Open.png';
import giftboxProducts from '../assets/images/gift-box/SmartPro-GiftBox-Products.png';
import giftboxClosed from '../assets/images/gift-box/SmartPro-GiftBox-Closed.png';

const IMAGE_ARRAY = [
  { src: giftboxOpen },
  { src: giftboxProducts },
  { src: giftboxClosed }
];

const PROJECT_TEXT = 'Remington paired with a number of YouTube influencers to create unboxing videos as a promotion for one of the products. The ask was to create a gift box for this purpose.';
const DESIGN_CHOICES_TEXT = 'Instead of finding an existing box, I drew up concepts for unique structures. Working with our Structural Engineering team - and budgetary constraints - we landed on a double flap opening with magnetic closure & custom formed insert to hold the product & supplemental gifts. Another designer turned a tagline into a lovely logo, which I used in a few ways. This product helped with moisture retention & healthy hair, so the other gifts were chosen to reinforce those ideas.';

const DESCRIPTIONS = {
  project: {
    title: 'PROJECT',
    text: PROJECT_TEXT
  },
  design: {
    title: 'DESIGN CHOICES',
    text: DESIGN_CHOICES_TEXT
  },
};

export default () => {
  const { setImages, setShowSlideshow, setSlideshowIndex } = useSlideshow();
  useEffect(() => {
    document.title = 'Adrienne\'s Gift Box';
  }, []);

  const openSlideshow = (index) => {
    setImages(IMAGE_ARRAY);
    setSlideshowIndex(index);
    setShowSlideshow(true);
  };

  return (
    <>
      <HeaderDeco deco={deco} title={title} descriptions={DESCRIPTIONS} />
      <div className="image-grid">
        <Image.FullWidth
          src={giftboxOpen}
          alt="Giftbox open"
          index={0}
          onClick={openSlideshow}
        />
        <Image.FullWidth
          src={giftboxProducts}
          alt="Products Inside Giftbox"
          index={1}
          onClick={openSlideshow}
        />
        <Image.FullWidth
          src={giftboxClosed}
          alt="Giftbox closed"
          index={2}
          onClick={openSlideshow}
        />
      </div>
    </>
  );
};
