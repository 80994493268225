import { useState, useEffect, useCallback } from 'react';

const CUT_OFF_WIDTH = 600;
const IS_CLIENT = typeof window === 'object';

export default (onChange) => {
  const [useMobile, setUseMobile] = useState(IS_CLIENT ? window.innerWidth < CUT_OFF_WIDTH : false);

  const handleResize = useCallback(() => {
    const isMobile = window.innerWidth < CUT_OFF_WIDTH;
    if (useMobile === isMobile) { return; }
    setUseMobile(isMobile);
    if (typeof onChange === 'function') {
      onChange(isMobile);
    }
  }, [useMobile, onChange]);

  useEffect(() => {
    if (!IS_CLIENT) {
      return false;
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return useMobile;
};
