import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Image from './Image';
import imageAW from '../assets/images/shared/center_AW_dots.svg';
import useMobile from './useMobile';

export default ({ setSidebarOpen }) => {
  const mobile = useMobile();
  const history = useHistory();

  const goToHome = () => {
    history.push('/');
  };

  return (
    <div className="nav">
      {!mobile ? <>
        <Link to="/">HOME</Link>
        <svg width="8px" height="8px"><circle r="3.3px" cx="4px" cy="4px" fill="#E6F5F7" /> </svg>
        <Link to="/work">WORK</Link>
      </> : null}
      <Image.Nav src={imageAW} onClick={mobile ? () => setSidebarOpen(true) : goToHome} />
      {!mobile ? <>
        <Link to="/about">ABOUT</Link>
        <svg width="8px" height="8px"><circle r="3.3px" cx="4px" cy="4px" fill="#E6F5F7" /> </svg>
        <Link to="/contact">CONTACT</Link>
      </> : null}
    </div>
  );
};
