import React, { useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useSlideshow } from './components/SlideshowProvider';
import GenericPage from './pages/Generic';
import ProjectPage from './pages/Project';

export default () => {
  const history = useHistory();
  const { setShowSlideshow } = useSlideshow();
  useEffect(() => {
    history.listen(() => { setShowSlideshow(false); });
    return history.unlisten;
  }, [history, setShowSlideshow]);

  return (
    <Switch>
      <Route path="/cards" ><ProjectPage page="cards" /></Route>
      <Route path="/combo" ><ProjectPage page="combo" /></Route>
      <Route path="/fem-dep" ><ProjectPage page="fem-dep" /></Route>
      <Route path="/gift-box" ><ProjectPage page="gift-box" /></Route>
      <Route path="/hairpix" ><ProjectPage page="hairpix" /></Route>
      <Route path="/heist" ><ProjectPage page="heist" /></Route>
      <Route path="/reveal" ><ProjectPage page="reveal" /></Route>
      <Route path="/tournament" ><ProjectPage page="tournament" /></Route>
      <Route path="/contact" ><GenericPage type="contact" /></Route>
      <Route path="/about" ><GenericPage type="about" /></Route>
      <Route path="/work" ><GenericPage type="work" /></Route>
      <Route path="/home" ><GenericPage type="home" /></Route>
      <Route path="/" ><GenericPage type="home" /></Route>
    </Switch>
  );
};
