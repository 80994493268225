import React, { useEffect } from 'react';
import { HeaderDeco, Image, LeadIn } from '../components';
import { useSlideshow } from '../components/SlideshowProvider';
import title from '../assets/images/reveal/title.svg';
import deco from '../assets/images/reveal/deco.svg';
import fc500Front from '../assets/images/reveal/FC500-front.png';
import fc500Back from '../assets/images/reveal/FC500-back.png';
import fc1000Front from '../assets/images/reveal/FC1000-front.png';
import fc1000Back from '../assets/images/reveal/FC1000-back.png';
import fc1500Front from '../assets/images/reveal/FC1500-front.png';
import fc1500Back from '../assets/images/reveal/FC1500-back.png';
import man3000Front from '../assets/images/reveal/MAN3000-front.png';
import man3000Back from '../assets/images/reveal/MAN3000-back.png';
import family from '../assets/images/reveal/family.png';

const IMAGE_ARRAY = [
  { src: fc500Front, title: 'Mini Brush Front', showTitle: true },
  { src: fc500Back, title: 'Mini Brush Back', showTitle: true },
  { src: fc1000Front, title: 'Brush Front', showTitle: true },
  { src: fc1000Back, title: 'Brush Back', showTitle: true },
  { src: fc1500Front, title: 'Brush for Men Front', showTitle: true },
  { src: fc1500Back, title: 'Brush for Men Back', showTitle: true },
  { src: man3000Front, title: 'Mani/Pedi Set Front', showTitle: true },
  { src: man3000Back, title: 'Mani/Pedi Set Back', showTitle: true },
  { src: family, title: 'Family of products' },
];

const PROJECT_TEXT = 'Packaging creation for integration of the Remington beauty line. Products include facial brushes, manicure & pedicure set, etc.';
const DESIGN_CHOICES_TEXT = 'New packaging created by our team for the Hair Care products informed the font, icons & overall hierarchy of REVEAL. Color scheme was derived from the existing UK beauty products. The goal was clean, sophisticated packaging to match the function & end result achieved with the use of products.';

const DESCRIPTIONS = {
  project: {
    title: 'PROJECT',
    text: PROJECT_TEXT
  },
  design: {
    title: 'DESIGN CHOICES',
    text: DESIGN_CHOICES_TEXT
  },
};

export default () => {
  const { setImages, setShowSlideshow, setSlideshowIndex } = useSlideshow();
  useEffect(() => {
    document.title = 'Adrienne\'s Reveal';
  }, []);

  const openSlideshow = (index) => {
    setImages(IMAGE_ARRAY);
    setSlideshowIndex(index);
    setShowSlideshow(true);
  };

  return (
    <>
      <HeaderDeco deco={deco} title={title} descriptions={DESCRIPTIONS} />
      <div className="image-grid">
        <Image.Flip
          src={fc500Front}
          srcBack={fc500Back}
          alt="Reveal FC500"
          index={0}
          indexBack={1}
          onClick={openSlideshow}
        />
        <Image.Flip
          src={fc1000Front}
          srcBack={fc1000Back}
          alt="Reveal FC1000"
          index={2}
          indexBack={3}
          onClick={openSlideshow}
        />
        <Image.Flip
          src={fc1500Front}
          srcBack={fc1500Back}
          alt="Reveal FC1500"
          index={4}
          indexBack={5}
          onClick={openSlideshow}
        />
        <Image.Flip
          src={man3000Front}
          srcBack={man3000Back}
          alt="Reveal MAN3000"
          index={6}
          indexBack={7}
          onClick={openSlideshow}
        />
      </div>
      <div className="image-grid">
        <LeadIn.Left />
        <Image.FullWidth
          src={family}
          alt="Reveal Family"
          index={8}
          onClick={openSlideshow}
        />
      </div>
    </>
  );
};
