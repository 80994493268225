import React, { useState, useEffect } from 'react';
import { HeaderDeco } from '../components';
import title from '../assets/images/contact/title.svg';
import deco from '../assets/images/contact/deco.svg';
var xhr = new XMLHttpRequest();

const regex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}/i;

let sentData = {};

export default () => {
  useEffect(() => {
    document.title = 'Contact Adrienne';
  }, []);

  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const onSubmit = (event) => {
    event.preventDefault();
    if (!name) {
      setError('Please provide your name');
    } else if (!email) {
      setError('Please provide your email');
    } else if (!regex.test(email)) {
      setError('Email format is invalid');
    } else if (!message) {
      setError('Please enter a message');
    } else if (sentData.name !== name || sentData.email !== email || sentData.subject !== subject || sentData.message !== message) {
      setError('Sent!');
      try {
        sentData = { name, email, subject, message };
        xhr.open('post', 'https://odhmcvk7yh.execute-api.us-east-1.amazonaws.com/test/contact');
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify(sentData));
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <>
      <HeaderDeco deco={deco} title={title}>
        <div className="generic-h2">
          QUESTIONS FOR ME?
        </div>
        <div className="generic-h2">
          COMMENTS?
        </div>
        <div className="generic-h3" style={{ marginTop: '5px' }}>
          I wanna know!
        </div>
        <div className="generic-h3" style={{ marginTop: '20px' }}>
          HIT ME UP.
        </div>
      </HeaderDeco>
      <div className="contact-box">
        <form className="contact-form" onSubmit={onSubmit} >
          <div className="contact-row">
            <input type="text" placeholder="NAME" value={name} onChange={(e) => setName(e.target.value)} />
            <input type="text" placeholder="EMAIL" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div className="contact-row">
            <input type="text" placeholder="SUBJECT" value={subject} onChange={(e) => setSubject(e.target.value)} />
          </div>
          <div className="contact-row">
            <textarea placeholder="MESSAGE" value={message} onChange={(e) => setMessage(e.target.value)} />
          </div>
          <div className="contact-row">
            <button type="submit">SUBMIT</button>
            <div className="contact-error-message">{error}</div>
          </div>
        </form>
        {/* <div className="contact-drop-line" /> */}
      </div>
    </>
  );
};
