import React from 'react';

function Left() {
  return (
    <svg width="2000px" height="10px" className="lead-in-left">
      <line x1="0px" y1="5px" x2="1995px" y2="5px" stroke="#394051" strokeWidth="2px" />
      <circle cx="1995px" cy="5px" r="4px" stroke="#394051" fill="#394051" />
    </svg>
  );
}

function Right() {
  return (
    <svg width="2000px" height="10px" className="lead-in-right">
      <line x1="5px" y1="5px" x2="1995px" y2="5px" stroke="#394051" strokeWidth="2px" />
      <circle cx="5px" cy="5px" r="4px" stroke="#394051" fill="#394051" />
    </svg>
  );
}

export default { Left, Right };
