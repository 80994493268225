import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HeaderDeco } from '../components';
import getWorkItems from '../assets/constants/getWorkItems';
import title from '../assets/images/work/title.svg';
import deco from '../assets/images/work/deco.svg';

export default () => {
  const workItems = getWorkItems();
  useEffect(() => {
    document.title = 'Adrienne\'s Work';
  }, []);

  return (
    <>
      <HeaderDeco deco={deco} title={title}>
        <div className="generic-h2">
          A SELECTION.
        </div>
        <div className="generic-h3" style={{ marginTop: '10px' }}>
          In-house, freelance,
        </div>
        <div className="generic-h3">
          & just for fun projects.
        </div>
        <div className="generic-h3" style={{ marginTop: '40px' }}>
          YOU GET IT.
        </div>
      </HeaderDeco>
      <div className="work-grid">
        {workItems.map(({ id, src, label, width, location }) =>
          <Link className="work-grid-item" to={location} key={label} id={id}>
            <div className="work-grid-overlay" />
            <svg height={10} width={width} className="work-grid-lead-in" style={{ left: '-' + width + 'px' }}>
              <line x1="0" x2={width - 5} y1="5px" y2="5px" stroke="#394051" strokeWidth="2px" />
              <circle cx={width - 5} cy="5px" r="4px" stroke="#394051" fill="#394051" />
            </svg>
            <div className="work-grid-item-label">{label}</div>
            <img src={src} width="100%" alt="family" />
          </Link>
        )}
      </div>
    </>
  );
};
