import React, { useEffect } from 'react';
import { Image, HeaderDeco, LeadIn } from '../components';
import { useSlideshow } from '../components/SlideshowProvider';
import title from '../assets/images/heist/title.svg';
import deco from '../assets/images/heist/deco.svg';
import fullDark from '../assets/images/heist/dark-jersey-full.png';
import fullTank from '../assets/images/heist/tank-top-full.png';
import fullWhite from '../assets/images/heist/white-jersey-full.png';
import darkFront from '../assets/images/heist/dark-jersey-front.png';
import darkBack from '../assets/images/heist/dark-jersey-back.png';
import tankFront from '../assets/images/heist/tank-top-front.png';
import tankBack from '../assets/images/heist/tank-top-back.png';
import whiteFront from '../assets/images/heist/white-jersey-front.png';
import whiteBack from '../assets/images/heist/white-jersey-back.png';
import promotionalDeck from '../assets/images/heist/promotional-deck.png';

const IMAGE_ARRAY = [
  { src: fullDark },
  { src: fullTank },
  { src: fullWhite },
  { src: darkFront },
  { src: darkBack },
  { src: tankFront },
  { src: tankBack },
  { src: whiteFront },
  { src: whiteBack },
  { src: promotionalDeck },
];

const PROJECT_TEXT = 'Jersey design for Heist, a competitive womxn’s Ultimate Frisbee team based in Madison, WI. Using the original logo design created in 2012, I provided designs for the 2017 season.';
const DESIGN_CHOICES_TEXT = 'With cheers such as “crack the vault” and plays including “bandit,” “money,” & “hostage,” I wanted the designs to reflect the secrecy, scheming & cunning teamwork that contribute to the perfect Heist. This can be seen in the looming Madison skyline, inter-working gears, and elongated shadows on the darks, lights & tanks respectively.';

const DESCRIPTIONS = {
  project: {
    title: 'PROJECT',
    text: PROJECT_TEXT
  },
  design: {
    title: 'DESIGN CHOICES',
    text: DESIGN_CHOICES_TEXT
  },
};

export default () => {
  const { setImages, setShowSlideshow, setSlideshowIndex } = useSlideshow();
  useEffect(() => {
    document.title = 'Adrienne\'s Heist';
  }, []);

  const openSlideshow = (index) => {
    setImages(IMAGE_ARRAY);
    setSlideshowIndex(index);
    setShowSlideshow(true);
  };

  return (
    <>
      <HeaderDeco deco={deco} title={title} descriptions={DESCRIPTIONS} />
      <div className="image-grid">
        <Image.FullWidth
          src={fullDark}
          alt="Dark Jersey"
          index={0}
          onClick={openSlideshow}
        />
        <Image.FullWidth
          src={fullTank}
          alt="Blue Tanktop"
          index={1}
          onClick={openSlideshow}
        />
        <Image.FullWidth
          src={fullWhite}
          alt="White Jersey"
          index={2}
          onClick={openSlideshow}
        />
      </div>
      <div className="image-grid">
        <LeadIn.Left />
        <Image.HalfWidth
          src={darkFront}
          alt="Darks in use from the front"
          index={3}
          onClick={openSlideshow}
        />
        <Image.HalfWidth
          src={darkBack}
          alt="Darks in use from the back"
          index={4}
          onClick={openSlideshow}
        />
        <Image.HalfWidth
          src={tankFront}
          alt="Tanks in use from the front"
          index={5}
          onClick={openSlideshow}
        />
        <Image.HalfWidth
          src={tankBack}
          alt="Tanks in use from the back"
          index={6}
          onClick={openSlideshow}
        />
        <Image.HalfWidth
          src={whiteFront}
          alt="Whites in use from the front"
          index={7}
          onClick={openSlideshow}
        />
        <Image.HalfWidth
          src={whiteBack}
          alt="Whites in use from the back"
          index={8}
          onClick={openSlideshow}
        />
      </div>
      <div className="image-grid">
        <LeadIn.Right />
        <Image.FullWidth
          src={promotionalDeck}
          alt="Promotional Fundraising Deck"
          index={9}
          onClick={openSlideshow}
        />
      </div>
    </>
  );
};
