import React, { useEffect } from 'react';
import { HeaderDeco, Image, LeadIn } from '../components';
import title from '../assets/images/about/title.svg';
import deco from '../assets/images/about/deco.svg';
import cats from '../assets/images/about/cats.svg';
import selfPortrait from '../assets/images/about/self-portrait.svg';

export default () => {
  useEffect(() => {
    document.title = 'About Adrienne';
  }, []);

  return (
    <>
      <HeaderDeco deco={deco} title={title}>
        <div className="generic-h2">
          IT'S STILL ME. ADRIENNE WELLS.
        </div>
        <div className="generic-h3" style={{ marginTop: '5px' }}>
          Here are some fast stats:
        </div>
        <ul className="generic-h4" style={{ marginTop: '15px' }}>
          <li>Book Lover</li>
          <li>Cat Lover</li>
          <li>Disillusioned Millennial</li>
          <li>Leo</li>
        </ul>
        <div className="generic-h3" style={{ marginTop: '0px' }}>
          READ ON FOR MORE!
        </div>
      </HeaderDeco>
      <div className="about-body">
        <LeadIn.Left />
        <Image.About
          src={selfPortrait}
          alt="Self Portrait"
          float="right"
        />
        <div className="about-details" style={{ marginTop: '120px' }}>
          I’m from the land of ice cream and cheese, where 35 and sunny is a balmy winter respite; it’s a place where Midwestern Nice means never taking the last french fry. And now I’m here, on the East Coast, by a pretty big lake you all call “the Ocean,” experiencing the phenomenon known as “traffic,” and occasionally eating large sea bugs, aka Lobstah.
        </div>
        <Image.About
          src={cats}
          alt="My Cats"
          float="left"
        />
        <br clear="right" />
        <div className="about-details" style={{ textAlign: 'right' }}>
          Yes, born and raised and educated in Madison, I am a certified Wisconsinite. After graduating from UW-Madison in 2014 and working as an in-house designer for almost 4 years, and having a variety of freelance projects under my belt, I am inspired by the change of scenery in our new home in Connecticut. With my graphic design knowledge, the help of my feline sidekicks, Pippin and Meeko - and of course my human partner - I welcome new experiences with open arms.
        </div>
      </div>
    </>
  );
};
