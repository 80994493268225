import reveal from '../images/work/reveal.png';
import heist from '../images/work/sports-team.png';
import tournaments from '../images/work/tournaments.png';
import depilation from '../images/work/depilation.png';
import comboKit from '../images/work/combo-kit.png';
import cards from '../images/work/cards.png';
import giftBox from '../images/work/gift-box.png';
import hairpix from '../images/work/hairpix.png';

export default () => {
  return [
    {
      'id': 'reveal',
      'src': reveal,
      'label': 'REVEAL',
      'width': 160,
      'location': '/reveal'
    },
    {
      'id': 'heist',
      'src': heist,
      'label': 'TEAM JERSEYS',
      'width': 280,
      'location': '/heist'
    },
    {
      'id': 'tournament',
      'src': tournaments,
      'label': 'TOURNAMENTS',
      'width': 290,
      'location': '/tournament'
    },
    {
      'id': 'fem-dep',
      'src': depilation,
      'label': 'FEMDEP CONCEPTS',
      'width': 365,
      'location': '/fem-dep'
    },
    {
      'id': 'hairpix',
      'src': hairpix,
      'label': 'LOGO DESIGN',
      'width': 270,
      'location': '/hairpix'
    },
    {
      'id': 'fun-combo',
      'src': comboKit,
      'label': 'COMBO KIT',
      'width': 225,
      'location': '/combo'
    },
    {
      'id': 'cards',
      'src': cards,
      'label': 'CARDS',
      'width': 150,
      'location': '/cards'
    },
    {
      'id': 'gift-box',
      'src': giftBox,
      'label': 'GIFT BOX',
      'width': 190,
      'location': '/gift-box'
    }
  ];
};
