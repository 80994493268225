import React, { useEffect } from 'react';
import { Image, HeaderDeco } from '../components';
import { useSlideshow } from '../components/SlideshowProvider';
import title from '../assets/images/cards/title.svg';
import deco from '../assets/images/cards/deco.svg';
import notTodayPants from '../assets/images/cards/not-today-pants.png';
import happyButtDay from '../assets/images/cards/happy-butt-day.png';
import aclRecovery from '../assets/images/cards/acl-recovery.png';
import connectiCats from '../assets/images/cards/connecti-cats.png';
import birthdayBirb from '../assets/images/cards/birthday-birb.png';
import birthdayCat from '../assets/images/cards/birthday-cat.png';

const IMAGE_ARRAY = [
  { src: notTodayPants, title: 'Quarantine Birthday', showTitle: true },
  { src: happyButtDay, title: 'Sunny-Side-Up Cat Birthday', showTitle: true },
  { src: aclRecovery, title: 'Third ACL Surgery Get Well', showTitle: true },
  { src: connectiCats, title: 'Greetings Postcard', showTitle: true },
  { src: birthdayBirb, title: 'Mother\'s Day Oriole', showTitle: true },
  { src: birthdayCat, title: 'Pom Pom Cat Birthday', showTitle: true },
];

const PROJECT_TEXT = 'Making cards for people.';
const DESIGN_CHOICES_TEXT = 'From birthdays to graduation, I\'ve always enjoyed the personalization of a homemade card, each one a unique design project in itself. Card making presents the opportunity to be general and far-reaching, or as specific and obscure as one likes, based on the recipient. Each is also an exploration of typography, illustration and color. The best part is bringing a bit of joy to the individual being celebrated.';

const DESCRIPTIONS = {
  project: {
    title: 'PROJECT',
    text: PROJECT_TEXT
  },
  design: {
    title: 'DESIGN CHOICES',
    text: DESIGN_CHOICES_TEXT
  },
};

export default () => {
  const { setImages, setShowSlideshow, setSlideshowIndex } = useSlideshow();
  useEffect(() => {
    document.title = 'Adrienne\'s Custom Cards';
  }, []);

  const openSlideshow = (index) => {
    setImages(IMAGE_ARRAY);
    setSlideshowIndex(index);
    setShowSlideshow(true);
  };

  return (
    <>
      <HeaderDeco deco={deco} title={title} descriptions={DESCRIPTIONS} />
      <div className="image-grid">
        <Image.HalfWidth
          src={notTodayPants}
          alt="No Pants Birthday Card"
          index={0}
          onClick={openSlideshow}
        />
        <Image.HalfWidth
          src={happyButtDay}
          alt="Happy Butt Day Card"
          index={1}
          onClick={openSlideshow}
        />
        <Image.HalfWidth
          src={aclRecovery}
          alt="ACL Recovery Card"
          index={2}
          onClick={openSlideshow}
        />
        <Image.HalfWidth
          src={connectiCats}
          alt="Connect-i-Cat Card"
          index={3}
          onClick={openSlideshow}
        />
        <Image.HalfWidth
          src={birthdayBirb}
          alt="Happy Birb Day Card"
          index={4}
          onClick={openSlideshow}
        />
        <Image.HalfWidth
          src={birthdayCat}
          alt="Pom Pom Birthday Card"
          index={5}
          onClick={openSlideshow}
        />
      </div>
    </>
  );
};
