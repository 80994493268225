import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import getWorkItems from '../assets/constants/getWorkItems';
import expandIcon from '../assets/images/shared/slides_chevron_right_white.svg';

export default ({ sidebarOpen, setSidebarOpen }) => {
  const workItems = getWorkItems();
  const history = useHistory();
  const [workOpen, _setWorkOpen] = useState(false);

  const setWorkOpen = (e) => {
    e.stopPropagation();
    _setWorkOpen(!workOpen);
  };

  const navigate = (destination) => {
    setSidebarOpen(false);
    history.push(destination);
  };

  return (
    <div className="sidebar" style={{ left: sidebarOpen ? 0 : '-100vw' }}>
      {sidebarOpen ? <div className="sidebar-overlay" onClick={() => setSidebarOpen(false)} /> : null}
      <div className="sidebar-container" onClick={(e) => e.stopPropagation()} >
        <div className="sidebar-list">
          <div onClick={() => navigate('/')} className="sidebar-list-item">
            <div className="sidebar-list-item-text">HOME</div>
          </div>
          <LeadIn />
          <div onClick={() => navigate('/work')} className="sidebar-list-item">
            <div className="sidebar-list-item-text">WORK</div>
            <div onClick={setWorkOpen} className="sidebar-list-item-expand">
              <img
                className="sidebar-expand-image"
                src={expandIcon}
                style={{ transform: workOpen ? 'rotate(90deg)' : 'rotate(0)' }}
                alt={(workOpen ? 'Expand' : 'Collapse') + ' work options'}
              />
            </div>
          </div>
          {workOpen ? workItems.map(({ id, location, label }) =>
            <div key={id} className="sidebar-list-subitem" onClick={() => navigate(location)}>
              – {label}
            </div>
          ) : null}
          <LeadIn />
          <div onClick={() => navigate('/about')} className="sidebar-list-item">
            <div className="sidebar-list-item-text">ABOUT</div>
          </div>
          <LeadIn />
          <div onClick={() => navigate('/contact')} className="sidebar-list-item">
            <div className="sidebar-list-item-text">CONTACT</div>
          </div>
        </div>
      </div>
    </div >
  );
};

const LeadIn = () => {
  return (
    <svg height="10px" width="225px">
      <line x1="0" y1="5px" x2="220px" y2="5px" stroke="#E6F5F7" strokeWidth="2px" />
      <circle cx="220px" cy="5px" r="4px" stroke="#E6F5F7" fill="#E6F5F7" />
    </svg>
  );
};
