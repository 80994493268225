import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import HomePage from './Home';
import WorkPage from './Work';
import AboutPage from './About';
import ContactPage from './Contact';
import { Footer } from '../components';

const TYPE_MAP = {
  'home': <HomePage />,
  'work': <WorkPage />,
  'about': <AboutPage />,
  'contact': <ContactPage />,
};

let lastPathname = '';
let lastScroll = 0;

export default ({ type }) => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    if (lastPathname !== pathname) {
      lastPathname = pathname;
    } else {
      console.log('return generic', lastScroll);
      window.scrollTo(0, lastScroll);
    }
    return () => {
      lastScroll = window.scrollY;
    };
  }, [pathname]);

  return <div className={'generic-page ' + type}>
    {TYPE_MAP[type]}
    <Footer />
  </div>;
};
