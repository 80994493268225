import React, { useEffect } from 'react';
import { Image, HeaderDeco } from '../components';
import { useSlideshow } from '../components/SlideshowProvider';
import title from '../assets/images/combo/title.svg';
import deco from '../assets/images/combo/deco.svg';
import ci1a125Front from '../assets/images/combo/CI1A125-front.png';
import ci1a125Back from '../assets/images/combo/CI1A125-back.png';
import sa100Front from '../assets/images/combo/SA100-front.png';
import sa100Back from '../assets/images/combo/SA100-back.png';
import comboFamily from '../assets/images/combo/combo-family.png';

const IMAGE_ARRAY = [
  { src: ci1a125Front, title: 'Combo 1 Front', showTitle: true },
  { src: ci1a125Back, title: 'Combo 1 Back', showTitle: true },
  { src: sa100Front, title: 'Combo 2 Front', showTitle: true },
  { src: sa100Back, title: 'Combo 2 Back', showTitle: true },
  { src: comboFamily, title: 'Family of Products' }
];

const PROJECT_TEXT = 'Concept packaging for line review meeting. The ask was for something bold, eye‑catching and fun.';
const DESIGN_CHOICES_TEXT = 'I wanted to emphasize & enhance the bright, snazzy colors chosen for the products without distracting from them. Modern graffiti art informed the pattern & execution of design. The font, icons and some design elements match the rest of the Hair Care packaging.';

const DESCRIPTIONS = {
  project: {
    title: 'PROJECT',
    text: PROJECT_TEXT
  },
  design: {
    title: 'DESIGN CHOICES',
    text: DESIGN_CHOICES_TEXT
  },
};

export default () => {
  const { setImages, setShowSlideshow, setSlideshowIndex } = useSlideshow();
  useEffect(() => {
    document.title = 'Adrienne\'s Combo Kit';
  }, []);

  const openSlideshow = (index) => {
    setImages(IMAGE_ARRAY);
    setSlideshowIndex(index);
    setShowSlideshow(true);
  };

  return (
    <>
      <HeaderDeco deco={deco} title={title} descriptions={DESCRIPTIONS} />
      <div className="image-grid">
        <Image.Flip
          src={ci1a125Front}
          srcBack={ci1a125Back}
          alt="D3020+CI1A125"
          index={0}
          indexBack={1}
          onClick={openSlideshow}
        />
        <Image.Flip
          src={sa100Front}
          srcBack={sa100Back}
          alt="Reveal FC1000"
          index={2}
          indexBack={3}
          onClick={openSlideshow}
        />
        <Image.FullWidth
          src={comboFamily}
          alt="Combo Family"
          index={4}
          onClick={openSlideshow}
        />
      </div>
    </>
  );
};
