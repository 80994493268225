import React, { createContext, useContext, useState } from 'react';
import close from '../assets/images/shared/slides_X.svg';
import left from '../assets/images/shared/slides_chevron_left.svg';
import right from '../assets/images/shared/slides_chevron_right.svg';

const SlideshowContext = createContext(null);
let scrollPosition = 0;

const SlideshowProvider = ({ children }) => {
  const [images, setImages] = useState([]);
  const [showSlideshow, _setShowSlideshow] = useState(false);
  const [slideshowIndex, setSlideshowIndex] = useState(0);

  const setShowSlideshow = (value) => {
    if (value === showSlideshow) { return; }
    if (value === false) {
      // document.body.style.overflowY = null;
      // document.body.style.paddingRight = null;
      console.log('Slidshow: scroll to old', scrollPosition);
      window.scrollTo(0, scrollPosition);
    } else {
      // document.body.style.paddingRight = (window.innerWidth - document.documentElement.clientWidth) + 'px';
      // document.body.style.overflowY = 'hidden';
      console.log('Slidshow: store scroll', window.pageYOffset);
      scrollPosition = window.pageYOffset;
    }
    _setShowSlideshow(value);
  };

  return (
    <SlideshowContext.Provider value={{ setImages, setShowSlideshow, setSlideshowIndex }}>
      {children}
      <SlideshowContainer
        images={images}
        showSlideshow={showSlideshow}
        slideshowIndex={slideshowIndex}
        setShowSlideshow={setShowSlideshow}
        setSlideshowIndex={setSlideshowIndex}
      />
    </SlideshowContext.Provider>
  );
};

const SlideshowContainer = ({ images, showSlideshow, slideshowIndex, setShowSlideshow, setSlideshowIndex }) => {
  if (!showSlideshow) { return null; }

  const moveForward = () => {
    slideshowIndex++;
    if (slideshowIndex >= images.length) {
      slideshowIndex = 0;
    }
    setSlideshowIndex(slideshowIndex);
  };

  const moveBack = () => {
    slideshowIndex--;
    if (slideshowIndex < 0) {
      slideshowIndex = images.length - 1;
    }
    setSlideshowIndex(slideshowIndex);
  };

  return (
    <div className="slideshow">
      <div className="slideshow-overlay" onClick={() => setShowSlideshow(false)} />
      <div className="slideshow-container" onClick={(e) => e.stopPropagation()}>
        <img src={images[slideshowIndex].src} alt="Slideshow" className="slideshow-image" />
        {images[slideshowIndex].showTitle ? <div className="slideshow-title">
          {images[slideshowIndex].title.toUpperCase()}
        </div> : null}
        <img src={left} alt="Left" className="slideshow-side left" onClick={moveBack} />
        <img src={right} alt="Right" className="slideshow-side right" onClick={moveForward} />
        <img src={close} alt="Close" className="slideshow-close clickable" onClick={() => setShowSlideshow(false)} />
        <svg className="slideshow-dots" height="20px" width={(images.length * 26) + 'px'} >
          {images.map((_, index) => <circle key={index} cx={(index * 26 + 13) + 'px'} cy="10px" r="5px" fill="#394051" />)}
          <circle cx={(slideshowIndex * 26 + 13) + 'px'} cy="10px" r="8px" strokeWidth="2px" stroke="#394051" fill="transparent" />
        </svg>
      </div>
    </div>
  );
};

const useSlideshow = () => {
  const toastHelpers = useContext(SlideshowContext);

  return toastHelpers;
};

export { SlideshowContext, useSlideshow };
export default SlideshowProvider;
