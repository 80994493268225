import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Cards from './ProjectCards';
import Combo from './ProjectCombo';
import FemDep from './ProjectFemDep';
import Giftbox from './ProjectGiftbox';
import Hairpix from './ProjectHairpix';
import Heist from './ProjectHeist';
import Reveal from './ProjectReveal';
import Tournament from './ProjectTournament';
import { Footer } from '../components';

const PAGE_MAP = {
  'cards': <Cards />,
  'combo': <Combo />,
  'fem-dep': <FemDep />,
  'gift-box': <Giftbox />,
  'hairpix': <Hairpix />,
  'heist': <Heist />,
  'reveal': <Reveal />,
  'tournament': <Tournament />,
};

export default ({ page }) => {
  const location = useLocation();

  useEffect(() => {
    console.log(location);
    window.scrollTo(0, 0);
  }, [location]);

  return <div className={'project-page ' + page}>
    {PAGE_MAP[page]}
    <Footer />
  </div>;
};

