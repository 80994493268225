import React, { useEffect } from 'react';
import { HeaderDeco } from '../components';
import title from '../assets/images/hello/title.svg';
import deco from '../assets/images/hello/deco.svg';

export default () => {
  useEffect(() => {
    document.title = 'Adrienne Wells';
  }, []);

  return (
    <>
      <HeaderDeco deco={deco} title={title}>
        <div className="generic-h2">
          IT'S ME. ADRIENNE.
        </div>
        <div className="generic-h3" style={{ marginTop: '5px' }}>
          Graphic designer, at your service.
        </div>
        <div className="generic-h4" style={{ marginTop: '12px' }}>
          »» DOFFS HAT««
        </div>
        <div className="generic-h3" style={{ marginTop: '8px' }}>
          I am excited that you've stopped by!
        </div>
        <div className="generic-h3" style={{ marginTop: '0' }}>
          Please have a  look around, at your leisure.
        </div>
        <div className="generic-h3" style={{ marginTop: '35px' }}>
          ENJOY!
        </div>
      </HeaderDeco>
    </>
  );
};
