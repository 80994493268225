import React, { useEffect } from 'react';
import { Image, HeaderDeco, LeadIn } from '../components';
import { useSlideshow } from '../components/SlideshowProvider';
import title from '../assets/images/tournament/title.svg';
import deco from '../assets/images/tournament/deco.svg';
import co18DarkDesign from '../assets/images/tournament/chill-out-2018-dark-design.svg';
import co18LightDesign from '../assets/images/tournament/chill-out-2018-light-design.svg';
import co18Disc from '../assets/images/tournament/chill-out-2018-disc.png';
import co18Jersey from '../assets/images/tournament/chill-out-2018-jersey.png';
import co19DarkDesign from '../assets/images/tournament/chill-out-2019-dark-design.svg';
import co19LightDesign from '../assets/images/tournament/chill-out-2019-light-design.svg';
import co19DarkJersey from '../assets/images/tournament/chill-out-2019-dark-jersey.png';
import co19LightJersey from '../assets/images/tournament/chill-out-2019-light-jersey.png';
import abao18DarkDesign from '../assets/images/tournament/all-bets-are-off-2018-dark-design.svg';
import abao18LightDesign from '../assets/images/tournament/all-bets-are-off-2018-light-design.svg';
import abao18Disc from '../assets/images/tournament/all-bets-are-off-2018-disc.png';
import abao18White from '../assets/images/tournament/all-bets-are-off-2018-jersey.png';
import abao19DarkDesign from '../assets/images/tournament/all-bets-are-off-2019-dark-design.svg';
import abao19LightDesign from '../assets/images/tournament/all-bets-are-off-2019-light-design.svg';
import abao19Disc from '../assets/images/tournament/all-bets-are-off-2019-disc.png';
import abao19White from '../assets/images/tournament/all-bets-are-off-2019-jersey.png';

const IMAGE_ARRAY = [
  { src: co18DarkDesign, isSvg: true },
  { src: co18LightDesign, isSvg: true },
  { src: co18Disc },
  { src: co18Jersey },
  { src: co19DarkDesign, isSvg: true },
  { src: co19LightDesign, isSvg: true },
  { src: co19DarkJersey },
  { src: co19LightJersey },
  { src: abao18DarkDesign, isSvg: true },
  { src: abao18LightDesign, isSvg: true },
  { src: abao18Disc },
  { src: abao18White },
  { src: abao19DarkDesign, isSvg: true },
  { src: abao19LightDesign, isSvg: true },
  { src: abao19Disc },
  { src: abao19White },
];

const PROJECT_TEXT = 'CUT Camp is an organization that brings Ultimate Frisbee to youth in various communities by putting on teaching clinics & tournaments. I have worked with them on occasion to provide logos for a few Midwest tournaments.';
const DESIGN_CHOICES_TEXT_1 = 'The “Naper Chill Out” logo contains familiar representations of the theme. Occurring at the end of December in Illinois, one could realistically see a shockingly low thermometer. The following year, I wanted to warm things up, and instead focus on the concept of “chilling out.” A polar bear is showing how it’s done - relaxing in a mug of hot cocoa.';
const DESIGN_CHOICES_TEXT_2 = 'Inspiration for the “All Bets Are Off” logo comes from the uncertainty of gambling. With the need to keep it kid-friendly, I only made subtle references to playing cards, which are so ubiquitous to this activity. The card motif is utilized in two distinct ways for the 2018 & 2019 tournaments. I wanted each design to remain tight but teetering on the edge of chaos.';

const DESCRIPTIONS = {
  project: {
    title: 'PROJECT',
    text: PROJECT_TEXT
  },
  design1: {
    title: 'DESIGN CHOICES',
    text: DESIGN_CHOICES_TEXT_1
  },
  design2: {
    title: '',
    text: DESIGN_CHOICES_TEXT_2
  },
};

export default () => {
  const { setImages, setShowSlideshow, setSlideshowIndex } = useSlideshow();
  useEffect(() => {
    document.title = 'Adrienne\'s Tournament Designs';
  }, []);

  const openSlideshow = (index) => {
    setImages(IMAGE_ARRAY);
    setSlideshowIndex(index);
    setShowSlideshow(true);
  };

  return (
    <>
      <HeaderDeco deco={deco} title={title} descriptions={DESCRIPTIONS} />
      <div className="image-grid">
        <Image.HalfWidth
          src={co18DarkDesign}
          alt="Naperville Chillout 2018 Dark Design"
          backgroundColor="white"
          index={0}
          onClick={openSlideshow}
        />
        <Image.HalfWidth
          src={co18LightDesign}
          alt="Naperville Chillout 2018 White Design"
          index={1}
          onClick={openSlideshow}
        />
        <Image.HalfWidth
          src={co18Disc}
          alt="Naperville Chillout 2018 Disc"
          index={2}
          onClick={openSlideshow}
        />
        <Image.HalfWidth
          src={co18Jersey}
          alt="Naperville Chillout 2018 Jersey"
          index={3}
          onClick={openSlideshow}
        />
      </div>
      <div className="image-grid">
        <LeadIn.Left />
        <Image.HalfWidth
          src={co19DarkDesign}
          alt="Naperville Chill Out 2019 Dark Design"
          index={4}
          onClick={openSlideshow}
        />
        <Image.HalfWidth
          src={co19LightDesign}
          backgroundColor="white"
          alt="Naperville Chill Out 2019 White Design"
          index={5}
          onClick={openSlideshow}
        />
        <Image.HalfWidth
          src={co19DarkJersey}
          alt="Naperville Chill Out 2019 Dark Jersey"
          index={6}
          onClick={openSlideshow}
        />
        <Image.HalfWidth
          src={co19LightJersey}
          alt="Naperville Chill Out 2019 White Tanktop"
          index={7}
          onClick={openSlideshow}
        />
      </div>
      <div className="image-grid">
        <LeadIn.Right />
        <Image.HalfWidth
          src={abao18DarkDesign}
          alt="All Bets Are Off 2018 Dark Design"
          backgroundColor="white"
          index={8}
          onClick={openSlideshow}
        />
        <Image.HalfWidth
          src={abao18LightDesign}
          backgroundColor="#525352"
          alt="All Bets Are Off 2018 White Design"
          index={9}
          onClick={openSlideshow}
        />
        <Image.HalfWidth
          src={abao18Disc}
          alt="All Bets Are Off 2018 Disc"
          index={10}
          onClick={openSlideshow}
        />
        <Image.HalfWidth
          src={abao18White}
          alt="All Bets Are Off 2018 Jersey"
          index={11}
          onClick={openSlideshow}
        />
      </div>
      <div className="image-grid">
        <LeadIn.Left />
        <Image.HalfWidth
          src={abao19DarkDesign}
          alt="All Bets Are Off 2019 Dark Design"
          backgroundColor="white"
          index={12}
          onClick={openSlideshow}
        />
        <Image.HalfWidth
          src={abao19LightDesign}
          alt="All Bets Are Off 2019 White Design"
          index={13}
          onClick={openSlideshow}
        />
        <Image.HalfWidth
          src={abao19Disc}
          alt="All Bets Are Off 2019 Disc"
          index={14}
          onClick={openSlideshow}
        />
        <Image.HalfWidth
          src={abao19White}
          alt="All Bets Are Off 2019 Jersey"
          index={15}
          onClick={openSlideshow}
        />
      </div>
    </>
  );
};
