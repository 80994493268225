import React, { useEffect } from 'react';
import { Image, HeaderDeco, LeadIn } from '../components';
import { useSlideshow } from '../components/SlideshowProvider';
import title from '../assets/images/hairpix/title.svg';
import deco from '../assets/images/hairpix/deco.svg';
import mood from '../assets/images/hairpix/mood.png';
import elements from '../assets/images/hairpix/elements.png';
import logoDark from '../assets/images/hairpix/logo-dark.png';
import logoLight from '../assets/images/hairpix/logo-light.png';
import boxes from '../assets/images/hairpix/boxes.png';
import businessCards from '../assets/images/hairpix/business-cards.png';

const IMAGE_ARRAY = [
  { src: mood },
  { src: elements },
  { src: logoDark },
  { src: logoLight },
  { src: boxes },
  { src: businessCards },
];

const PROJECT_TEXT = 'Logo design for a hair stylist’s supplemental business selling hair care products.';
const DESIGN_CHOICES_TEXT = 'This option was intended to be bold, simple and modern, while referencing the concept of hair and choice. The logo is meant to display confidence in the product selection made and sold. It also maintains ties to the beauty category without being overtly feminine.';

const DESCRIPTIONS = {
  project: {
    title: 'PROJECT',
    text: PROJECT_TEXT
  },
  design: {
    title: 'DESIGN CHOICES',
    text: DESIGN_CHOICES_TEXT
  },
};

const ExtLink = ({ url, text }) => {
  return <a href={url} target="_blank" rel="noopener noreferrer">{text}</a>;
};

export default () => {
  const { setImages, setShowSlideshow, setSlideshowIndex } = useSlideshow();
  useEffect(() => {
    document.title = 'Adrienne\'s Hairpix Web Design';
  }, []);

  const openSlideshow = (index) => {
    setImages(IMAGE_ARRAY);
    setSlideshowIndex(index);
    setShowSlideshow(true);
  };

  return (
    <>
      <HeaderDeco deco={deco} title={title} descriptions={DESCRIPTIONS} />
      <div className="image-grid">
        <Image.FullWidth
          src={mood}
          alt="Mood Board"
          index={0}
          onClick={openSlideshow}
        />
        <div className="photo-credits">
          <div className="photo-credits-text">
            Most imagery found on Pinterest. I attempted to find and list original sources:
          </div>
          <div className="photo-credits-text">
            1. via Pinterest
          </div>
          <div className="photo-credits-text">
            2. <ExtLink url="https://www.behance.net/gallery/78545095/Rising-Sun-Branding-Kit-by-Polar-Vector" text="Branding Kit" />
          </div>
          <div className="photo-credits-text">
            3. <ExtLink url="https://www.brooke-smart.com" text="Brooke Smart" />
          </div>
          <div className="photo-credits-text">
            4. <ExtLink url="https://decordesigntrends.com/interior-color-trends-2020" text="Decor and Design Trends" />
          </div>
          <div className="photo-credits-text">
            5. <ExtLink url="https://tiffanylusteg.com" text="Tiffany Lusteg" />
          </div>
          <div className="photo-credits-text">
            6. <ExtLink url="https://worldbranddesign.com/brand-creations-and-packaging-design-for-student-concept-of-old-postcard-looking-chocolate" text="Student Packaging Concept" />
          </div>
          <div className="photo-credits-text">
            7. <ExtLink url="https://www.behance.net/gallery/59165647/jetlag-brand-identity" text="Jet Lag Branding" />
          </div>
        </div>
        <Image.FullWidth
          src={elements}
          alt="Design Elements"
          index={1}
          onClick={openSlideshow}
        />
      </div>
      <div className="image-grid">
        <LeadIn.Left />
        <Image.HalfWidth
          src={logoDark}
          alt="Dark Logo"
          index={2}
          onClick={openSlideshow}
        />
        <Image.HalfWidth
          src={logoLight}
          alt="Light Logo"
          index={3}
          onClick={openSlideshow}
        />
        <Image.HalfWidth
          src={businessCards}
          alt="Business Cards"
          index={4}
          onClick={openSlideshow}
        />
        <Image.HalfWidth
          src={boxes}
          alt="Gift Boxes"
          index={5}
          onClick={openSlideshow}
        />
      </div>
    </>
  );
};
