import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import SlideshowProvider from './components/SlideshowProvider';
import useMobile from './components/useMobile';
import { Header, Sidebar } from './components';
import Routes from './Routes';

import './index.css';

let pause = false;

function App() {
  const [sidebarOpen, _setSidebarOpen] = useState(false);   // XBDN

  const onMobileChange = (isMobile) => {
    if (sidebarOpen && !isMobile) {
      _setSidebarOpen(false);
    }
  };

  const mobile = useMobile(onMobileChange);

  const setSidebarOpen = (state) => {
    if (!mobile || pause) { return; }
    _setSidebarOpen(state);
    pause = true;
    setTimeout(() => {
      pause = false;
    }, 200);
  };

  return (
    <SlideshowProvider>
      <div className={'app-container ' + (mobile ? 'mobile' : 'desktop') + '-container base-container'}>
        <BrowserRouter>
          <Header setSidebarOpen={setSidebarOpen} />
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <Routes />
        </BrowserRouter>
      </div>
    </SlideshowProvider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
