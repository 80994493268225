import React from 'react';
import Image from './Image';

export default ({ deco, title, descriptions = {}, children }) => {
  return <div className="deco" id="header">
    <Image.Deco src={deco} />
    <div className="deco-title">
      <Image.DecoTitle src={title} />
      {Object.keys(descriptions).map((id) =>
        <div className="section" id={id} key={id}>
          <div className="section-content">
            {descriptions[id].title !== '' ? <div className="section-title">
              {descriptions[id].title}
            </div> : null}
            <div className="section-main-text">
              {descriptions[id].text}
            </div>
          </div>
        </div>
      )}
      {children}
    </div>
  </div>;
}