import React, { useState, useEffect } from 'react';
import flip from '../assets/images/shared/icons_flip_1B.svg';

function HalfWidth({ src, alt, backgroundColor, index, onClick }) {
  return (
    <div className="image-half-width" style={{ backgroundColor }}>
      <div className="image-half-width-container clickable">
        <img src={src} alt={alt} className="set-aspect" onClick={() => onClick(index)} />
      </div>
    </div>
  );
}

function About({ src, alt, backgroundColor, float }) {
  return (
    <div className="image-about" style={{ backgroundColor, float }} >
      <img src={src} alt={alt} />
    </div>
  );
}

function Flip({ src, srcBack, alt, backgroundColor, index, indexBack, onClick }) {
  const [front, setFront] = useState(true);
  const [canFlip, setCanFlip] = useState(true);
  let isMounted = true;
  useEffect(() => {
    return () => {
      // eslint-disable-next-line
      isMounted = false;
    };
  }, []);
  const showStyle = { transform: 'scale(1, 1)' };
  const hideStyle = { transform: 'scale(0, 1)' };
  const onFlip = () => {
    if (!canFlip) { return; }
    setCanFlip(false);
    setFront(!front);
    setTimeout(() => {
      if (!isMounted) { return; }
      setCanFlip(true);
    }, 800);
  };
  return (
    <div className="image-half-width image-flip" style={{ backgroundColor }}>
      <div className="image-half-width-container clickable">
        <img src={src} alt={alt}
          className={'set-aspect' + (front ? ' active' : '')}
          style={front ? showStyle : hideStyle}
          onClick={() => onClick(index)}
        />
        <img src={srcBack} alt={alt}
          className={'set-aspect' + (front ? '' : ' active')}
          style={front ? hideStyle : showStyle}
          onClick={() => onClick(indexBack)}
        />
        <img src={flip} alt="Flip to back"
          className="icon-flip clickable"
          onClick={onFlip}
          style={{ opacity: canFlip ? 1 : 0.4 }}
        />
      </div>
    </div>
  );
}

function FullWidth({ src, alt, index, onClick }) {
  return (
    <div className="image-full-width clickable">
      <img src={src} alt={alt} onClick={() => onClick(index)} />
    </div>
  );
}

function Narrow({ src, alt }) {
  return (
    <div className="image-narrow-width">
      <img src={src} alt={alt} />
    </div>
  );
}

function Accent({ src, alt }) {
  return (
    <div className="image-accent">
      <img src={src} alt={alt} />
    </div>
  );
}

function Deco({ src, alt }) {
  return (
    <div className="image-deco">
      <img src={src} alt={alt} />
    </div>
  );
}

function DecoTitle({ src, alt }) {
  return (
    <div className="image-deco-title">
      <img src={src} alt={alt} />
    </div>
  );
}

function Nav({ src, alt, onClick }) {
  return (
    <div className="image-nav clickable" onClick={onClick}>
      <img src={src} alt={alt} />
    </div>
  );
}

function Footer({ src, alt }) {
  return (
    <div className="image-footer">
      <img src={src} alt={alt} />
    </div>
  );
}

export default { HalfWidth, About, FullWidth, Narrow, Accent, Flip, Deco, DecoTitle, Nav, Footer };
