import React, { useEffect } from 'react';
import { Image, HeaderDeco, LeadIn } from '../components';
import { useSlideshow } from '../components/SlideshowProvider';
import title from '../assets/images/fem-dep/title.svg';
import deco from '../assets/images/fem-dep/deco.svg';
import mood from '../assets/images/fem-dep/mood.png';
import elements from '../assets/images/fem-dep/elements.png';
import bladeShaver from '../assets/images/fem-dep/3-blade-shaver.png';
import electicShaver from '../assets/images/fem-dep/electric-shaver.png';
import family from '../assets/images/fem-dep/family.png';

const IMAGE_ARRAY = [
  { src: mood },
  { src: elements },
  { src: bladeShaver },
  { src: electicShaver },
  { src: family, title: 'Family of Products' }
];

const PROJECT_TEXT = 'Redesign of the existing Remington packaging for Women\'s hair removal products. The ask was for a cleaner, more modern look that supported the product and "Smooth & Silky" tagline.';
const DESIGN_CHOICES_TEXT = 'This concept was meant to be simple and more mature, while avoiding being too bare. I utilized florals to reference our previous packaging but done in a subtler way. It is common imagery to illicit softness, the natural and organic, but I wanted to use the florals as an accent, a pop behind the product, not intended to overwhelm it or the package. The color scheme also supports softness & flora, by using light blush as a neutral background, and jade green to contrast and support the barely-there-pink.';

const DESCRIPTIONS = {
  project: {
    title: 'PROJECT',
    text: PROJECT_TEXT
  },
  design: {
    title: 'DESIGN CHOICES',
    text: DESIGN_CHOICES_TEXT
  },
};

const ExtLink = ({ url, text }) => {
  return <a href={url} target="_blank" rel="noopener noreferrer">{text}</a>;
};

export default () => {
  const { setImages, setShowSlideshow, setSlideshowIndex } = useSlideshow();
  useEffect(() => {
    document.title = 'Adrienne\'s Fem Dep';
  }, []);

  const openSlideshow = (index) => {
    setImages(IMAGE_ARRAY);
    setSlideshowIndex(index);
    setShowSlideshow(true);
  };

  return (
    <>
      <HeaderDeco deco={deco} title={title} descriptions={DESCRIPTIONS} />
      <div className="image-grid">
        <Image.FullWidth
          src={mood}
          alt="Mood Board"
          index={0}
          onClick={openSlideshow}
        />
        <div className="photo-credits">
          <div className="photo-credits-text">
            Most imagery found on Pinterest. I attempted to find and list original sources:
          </div>
          <div className="photo-credits-text">
            1. viaPinterest
          </div>
          <div className="photo-credits-text">
            2. <ExtLink url="https://thedieline.com/blog/2018/4/24/the-dieline-awards-2018-outstanding-achievements-biossance" text="Biossance via The Dieline" />
          </div>
          <div className="photo-credits-text">
            3. <ExtLink url="https://www.debitreloar.com" text="Debi Treloar photography" />
          </div>
          <div className="photo-credits-text">
            4. <ExtLink url="https://www.instagram.com/p/8eLa02pao3" text="Herbivore Instagram" />
          </div>
          <div className="photo-credits-text">
            5. <ExtLink url="https://www.weddingomania.com" text="Weddingomania" />
          </div>
          <div className="photo-credits-text">
            6. Image at cafe <ExtLink url="https://www.moby3143.com.au/" text="Moby" /> designed by <ExtLink url="http://designbygolden.com.au/" text="Golden" />, photographed by <ExtLink url="https://www.sharyncairns.com.au/" text="Sharyn Cairns" />
          </div>
          <div className="photo-credits-text">
            7. <ExtLink url="https://us6.campaign-archive.com/?u=9af3a1bda5cda8ba199b84b69&id=a81bcc95cb&e=f0c34ad629" text="Magnolia Home paint" />
          </div>
          <div className="photo-credits-text">
            8. <ExtLink url="https://www.designspiration.com/save/892151975248/" text="Designspiration" />
          </div>
          <div className="photo-credits-text">
            9. <ExtLink url="https://www.italianbark.com/pantone-2016-interiors-and-design-inspirations/" text="Italian Bark, House of Doctor chairs" />
          </div>
          <div className="photo-credits-text">
            10. <ExtLink url="https://www.etsy.com/listing/477367545/blush-pink-leaves-above-bed-wall-art?ref=shop_home_active_2" text="PrintsProject" />
          </div>
        </div>
        <Image.FullWidth
          src={elements}
          alt="Product Elements"
          index={1}
          onClick={openSlideshow}
        />
      </div>
      <div className="image-grid">
        <LeadIn.Left />
        <Image.HalfWidth
          src={bladeShaver}
          alt="3 Blade Shaver"
          index={2}
          onClick={openSlideshow}
        />
        <Image.HalfWidth
          src={electicShaver}
          alt="Electric Shaver"
          index={3}
          onClick={openSlideshow}
        />
        <Image.FullWidth
          src={family}
          alt="Family of Products"
          index={4}
          onClick={openSlideshow}
        />
      </div>
    </>
  );
};
